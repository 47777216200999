import React, { useContext, useState, useEffect } from "react"
import { connect } from "react-redux"
import { graphql, useStaticQuery } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import Layout from "../components/layout"
import Inner from "../components/inner"
import Heading from "../components/heading"
import Center from "../components/center"
import HalfHero from "../components/half-hero"
import BottomColorNav from "../components/bottom-color-nav"
import SEO from "../components/seo"
import Button from "../components/button"
import SVG from "../components/svg"

const FindInStoresStyled = styled.div`
  padding: 0px 0 100px 0;

  h1,
  a,
  p {
    color: ${(p) => p.theme.DarkBlue};
  }

  .center {
    text-align: center;
  }
`

const MapStyled = styled.div`
  position: relative;
  margin: 0px auto;
  width: 100%;
  max-width: 1400px;
  height: 1000px;

  .map {
    border: none;
    margin: 50px auto;
    max-width: 1400px;
    width: 100%;
    height: 1000px;
  }
`

const Map = ({ queryString }) => {
  const destini = "//destinilocators.com/nadamoo/site/locator.php?"
  return (
    <MapStyled>
      {queryString ? (
        <iframe
          id="destini"
          title="map"
          className="map"
          src={`${destini}${queryString}`}
        />
      ) : (
        ""
      )}
    </MapStyled>
  )
}

const CTA = ({ fields }) => {
  const theme = useContext(ThemeContext)
  return (
    <div>
      <Center margin="50px 0">
        <SVG name="SWave" className="wave swave" color={theme.LightBlue} />
      </Center>
      <Heading margin="50px 0px 30px 0">
        <h2>{fields.request.headline}</h2>
        <h3>{fields.request.sub_heading}</h3>
      </Heading>
      <Inner>
        <div className="center">
          <p
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: fields.request.instructions,
            }}
          />
          <Button target="_blank" href={fields.request.button_link.source_url}>
            {fields.request.button_label}
          </Button>
        </div>
      </Inner>
    </div>
  )
}

const FindInStores = ({ fields }) => {
  const [queryString, setQueryString] = useState(false)
  useEffect(() => {
    let query = window.location.search
    if (query) {
      query = query.slice(1, query.length)
    }
    setQueryString("MM=panel2&" + query)
  }, [])

  return (
    <FindInStoresStyled>
      <HalfHero fields={fields} align="left" />
      <CTA fields={fields} />
      <Map queryString={queryString} />
      <CTA fields={fields} />
    </FindInStoresStyled>
  )
}

const ConnectedFindInStores = connect((state) => state)(FindInStores)

const FindInStoresPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { slug: { eq: "find-in-stores-page" } }) {
        edges {
          node {
            acf {
              hero_area {
                top_line
                headline
                top_line_color
                headline_color
                wave_color
                background {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2560, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              search_title
              search_description
              share_headline
              share_comment
              share_image {
                source_url
              }
              request {
                headline
                sub_heading
                instructions
                button_label
                button_link {
                  source_url
                }
              }
            }
          }
        }
      }
    }
  `)

  const fields = data.allWordpressPage.edges[0].node.acf
  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields

  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <ConnectedFindInStores fields={fields} />
      <BottomColorNav />
    </Layout>
  )
}

export default FindInStoresPage
