import React from "react"
import styled from "styled-components"

const HeadingStyled = styled.div`
  margin: ${(p) => p.margin || "0px"};
  z-index: 1;
  padding: ${(p) => p.padding || "0px"};
  position: relative;

  @media (max-width: 768px) {
    margin: ${(p) => p.mobileMargin || "50px 0 20px 0"};
    padding: ${(p) => p.padding || "0px"};
  }

  h2 {
    text-align: center;
    color: ${(props) => props.theme.DarkBlue};
    margin: 0;
    text-transform: uppercase;
    font-size: 6em;
    line-height: 0.9em;
    font-weight: 800;
    letter-spacing: 2px;
    font-family: "interstate-compressed";
    @media (max-width: 768px) {
      line-height: 1em;
    }
  }

  h3 {
    text-align: center;
    text-transform: uppercase;
    color: ${(props) => props.theme.Blue};
    margin: 10px 0 0px 0;
    line-height: 1.2em;
    font-size: 2em;
    letter-spacing: 1.2px;
    font-family: "Montserrat";
  }

  p {
    color: ${(props) => props.theme.DarkBlue};
    max-width: 600px;
    margin: 0px auto;
    padding: 10px 0;
    font-weight: normal;
    text-align: center;
  }

  a {
    color: ${(props) => props.theme.DarkBlue};
  }
`

const Heading = (props) => {
  const { children, ...rest } = props
  return <HeadingStyled {...rest}>{children}</HeadingStyled>
}

export default Heading
